import React from 'react';
import OgImage from '../images/og-me.webp';
import { Script } from 'gatsby';

type Props = {
  title?: string;
  description?: string;
  keywords?: string[];
  children?: React.ReactNode;
};

const isDev = process.env.NODE_ENV === 'development';

export const Seo = ({ title, description, keywords, children }: Props) => {
  const seo = {
    title: title ? title + ' | Future Nostalgia' : 'Future Nostalgia | Christian Heinz',
    description: description || 'Portfolio von Webentwickler und Designer Christian Heinz',
    keywords: keywords
      ? keywords.join(', ')
      : 'Nostalgia, Design, Entwicklung, UX, UI, Portfolio, Webdesign, Website, Webentwickler',
    image: OgImage,
    url: 'https://www.fu-no.de',
  };

  return (
    <>
      <html lang='de' />
      <title>{seo.title}</title>
      <meta name='description' content={seo.description} />
      <meta name='image' content={seo.image} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:url' content={seo.url} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.image} />
      <meta name='keywords' content={seo.keywords} />
      <link rel='icon' type='image/png' href={'/favicon.ico'} sizes='16x16' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0, viewport-fit=cover' />
      <meta charSet='utf-8' />
      {!isDev && (
        <Script
          src='https://umami.fu-no.de/script.js'
          data-website-id='a409e07a-d7bc-44b0-b3a5-e6789814291c'
        />
      )}
      {children}
    </>
  );
};
